<template>
  <div>
    <v-form>
      <ValidationObserver ref="basicInfoValidationObserver">
        <v-row class="ma-4">
          <v-col cols="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="policyNumberValidationProvider"
              v-slot="{ errors }"
              name="Policy Number"
              rules="required|max:30|min:3"
            >
              <v-text-field
                :id="'Policy Number'"
                v-model="policyNumber"
                outlined
                label="Policy Number*"
                :counter="30"
                :maxlength="30"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="insurerNameValidationProvider"
              v-slot="{ errors }"
              name="Insurer Name"
              rules="alphaSpaceDot|required|max:300|min:3"
            >
              <v-text-field
                :id="'Insurer_Name'"
                v-model="nameOfInsurer"
                outlined
                :counter="300"
                :maxlength="300"
                label="Name of Insurer*"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="insuredNameValidationProvider"
              v-slot="{ errors }"
              name="Insured Name"
              rules="alphaSpaceDot|required|max:300|min:3"
            >
              <v-text-field
                :id="'Insured_Name'"
                v-model="nameOfInsured"
                outlined
                :counter="300"
                :maxlength="300"
                label="Name of Insured*"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="sumInsuredValidationProvider"
              v-slot="{ errors }"
              name="Sum Insured"
              rules="required|numeric|min:2"
            >
              <v-text-field
                :id="'Sum Insured'"
                v-model="sumInsured"
                outlined
                label="Sum Insured*"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider>
              <v-dialog v-model="expiryDateModal" width="290">
                <template #activator="{ on }">
                  <ValidationProvider
                    ref="expireDateValidationProvider"
                    v-slot="{ errors }"
                    name="Expiry Date"
                    rules="required"
                  >
                    <v-text-field
                      :id="'ExpiryDate'"
                      outlined
                      :value="formatDate(expireDate)"
                      label="Expiry Date of Insurance*"
                      v-on="on"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <v-date-picker
                  v-model="expireDate"
                  @change="expiryDateModal = false"
                ></v-date-picker>
              </v-dialog>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" lg="6" md="6" xl="6" class="mb-2">
            <DragAndDropFiles
              :id="'ProfessionalIndemnity'"
              file-category="insurance"
              :old-file-name="fileName"
              :key-index="'professionalindemnity-File-Upload'"
              @file-event-success="updateFileName($event)"
              @file-event-triggered="$emit('file-event-triggered')"
            ></DragAndDropFiles>
          </v-col>
          <v-col cols="12">
            <ValidationProvider
              ref="descriptionValidator"
              v-slot="{ errors }"
              name="Description"
              rules="descriptionValidation|max:1000|min:2"
            >
              <v-textarea
                :id="'Description'"
                v-model="description"
                outlined
                auto-grow
                :counter="1000"
                :maxlength="1000"
                label="Specify any exclusions and deductibles to the above Insurance Policy."
                :error-messages="errors"
              ></v-textarea>
            </ValidationProvider>
          </v-col>
        </v-row>
      </ValidationObserver>
      <v-divider></v-divider>
    </v-form>
  </div>
</template>

<script>
import DragAndDropFiles from "@/components/custom-components/DragAndDropFiles.vue";
import moment from "moment";

export default {
  name: "ProfessionalIndemnity",

  components: {
    DragAndDropFiles,
  },

  props: {
    personalInfo: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    // fields
    insuranceId: 0,
    policyNumber: "",
    nameOfInsurer: "",
    nameOfInsured: "",
    sumInsured: "",
    expireDate: "",
    description: "",
    fileName: "",
    fileNameWithPath: "",

    // others
    expiryDateModal: false,
  }),

  computed: {
    formatDate() {
      return (date) => {
        return date ? moment(date).format("DD/MM/YYYY") : "";
      };
    },
  },

  mounted() {
    if (this.personalInfo && Object.keys(this.personalInfo).length > 0) {
      this.insuranceId = this.personalInfo.insuranceId
        ? this.personalInfo.insuranceId
        : 0;
      this.policyNumber = this.personalInfo.policyNumber;
      this.nameOfInsurer = this.personalInfo.nameOfInsurer;
      this.nameOfInsured = this.personalInfo.nameOfInsured;
      this.sumInsured = this.personalInfo.sumInsured;
      this.expireDate = this.personalInfo.expiryDate;
      this.description = this.personalInfo.description;
      this.fileName = this.personalInfo.documentFile
        ? this.personalInfo.documentFile.split("?")[3]
        : "";
      this.fileNameWithPath = this.personalInfo.documentFile;
    }
  },

  methods: {
    updateFileName(fileName) {
      this.fileName = fileName ? fileName.split("?")[3] : "";
      this.fileNameWithPath = fileName;
    },
    basicInfoFields() {
      return {
        insuranceId: this.insuranceId,
        policyNumber: this.policyNumber,
        nameOfInsurer: this.nameOfInsurer,
        nameOfInsured: this.nameOfInsured,
        sumOfInsured: parseInt(this.sumInsured),
        expireDate: this.expireDate,
        fileName: this.fileNameWithPath,
        description: this.description,
        typeOfInsurance: "Professional Indemnity Insurance",
      };
    },
    validateBasicInfo() {
      return new Promise((resolve) => {
        this.$refs.basicInfoValidationObserver
          .validate()
          .then((validationResponse) => {
            resolve(validationResponse);
          });
      });
    },
  },
};
</script>
